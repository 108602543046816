<template>
  <div class="shipping">
    <div class="facilityBox">
      <el-tabs v-model="activeCmpt" @tab-click="resetForm">
        <el-tab-pane
          :label="item.statusName"
          :name="item.status"
          v-for="(item, index) in statusList"
          :key="index"
        ></el-tab-pane>
      </el-tabs>
      <el-form
        class="manageForm"
        :model="queryForm"
        :inline="true"
        size="medium"
        label-width="140px"
      >
        <el-form-item label="企业名称：">
          <el-input
            class="fromInp"
            v-model="queryForm.EnterpriseName"
            placeholder="输入企业名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="船舶登记号：">
          <el-input
            class="fromInp"
            v-model="queryForm.ShipRegNumber"
            placeholder="输入船舶登记号"
          ></el-input>
        </el-form-item>
        <el-form-item label="船舶类型：">
          <el-input
            class="fromInp"
            v-model="queryForm.ShipType"
            placeholder="输入船舶类型"
          ></el-input>
        </el-form-item>
        <el-form-item label="船舶编号：">
          <el-input
            class="fromInp"
            v-model="queryForm.ShipNO"
            placeholder="输入船舶编号"
          ></el-input>
        </el-form-item>
        <el-form-item label="船舶名称：">
          <el-input
            class="fromInp"
            v-model="queryForm.ShipName"
            placeholder="输入船舶名称"
          ></el-input>
        </el-form-item>
        <el-form-item
          :label="statusList.find((it) => it.status == activeCmpt).timeTxt + '：'"
        >
          <el-date-picker
            v-model="queryForm.searchDate"
            type="daterange"
            range-separator="至"
            start-placeholder="请选择开始时间"
            end-placeholder="请选择结束时间"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label-width="10px">
          <el-button type="primary" size="medium" @click="search" icon="el-icon-search"
            >搜索</el-button
          >
          <el-button type="primary" size="medium" icon="el-icon-delete" @click="resetForm"
            >清空</el-button
          >
        </el-form-item>
      </el-form>
      <el-table
        :data="tableData"
        :header-cell-style="{ background: '#f0f0f0', color: '#666' }"
        v-loading="loading"
        :key="activeCmpt"
      >
        <el-table-column
          type="index"
          align="center"
          label="序号"
          width="50"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          align="center"
          prop="EnterpriseName"
          label="企业名称"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          align="center"
          prop="ShipName"
          label="船舶名称"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          align="center"
          prop="ShipRegNumber"
          label="船舶登记号"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          align="center"
          prop="ShipType"
          label="船舶类型"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          align="center"
          prop="ShipNO"
          label="船舶编号"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          align="center"
          prop="StatusTime"
          :label="statusList.find((it) => it.status == activeCmpt).timeTxt"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          align="center"
          prop="Remark"
          label="驳回原因"
          width="200"
          show-overflow-tooltip
          v-if="activeCmpt == '1'"
        ></el-table-column>
        <el-table-column align="center" label="操作" fixed="right">
          <template slot-scope="scope">
            <el-button type="primary" @click="toDetail(scope.row)">{{
              scope.row.Status == 10 ? "审核" : "详情"
            }}</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div style="margin-top: 10px">
        <el-pagination
          background
          class="pagination"
          @current-change="handleCurrentChange"
          :current-page.sync="pagination.page"
          :page-size="pagination.pagesize"
          layout="total, prev, pager, next, jumper"
          :total="pagination.total"
        >
        </el-pagination>
      </div>
    </div>
    <el-dialog
      class="showAddDialog"
      append-to-body
      :visible.sync="showAddDialog"
      width="80%"
      v-if="showAddDialog"
      :close-on-click-modal="false"
    >
      <span slot="title" class="dialog-title">{{
        dialogType == "detail" ? "查看详情" : !currentItem ? "新增船舶" : "编辑船舶"
      }}</span>

      <div class="body">
        <el-form
          class="addForm"
          :model="addForm"
          :rules="addRule"
          ref="addForm"
          :disabled="dialogType == 'detail'"
        >
          <el-row>
            <el-col :span="12">
              <div class="title">证件信息</div>
              <div class="content">
                <div class="upBox">
                  <UploadImg
                    :loading="authForm.RiverLicenseURL.loading"
                    :uploaded="authForm.RiverLicenseURL.uploaded"
                    :picUrl="authForm.RiverLicenseURL.picUrl"
                    @update:file="updateFile"
                    @change="uploadCertificate(authForm.RiverLicenseURL)"
                    @deleteImg="deleteImg(authForm.RiverLicenseURL)"
                    v-if="dialogType != 'detail'"
                  >
                  </UploadImg>
                  <el-image
                    :src="authForm.RiverLicenseURL.picUrl"
                    :preview-src-list="srcList"
                    class="img"
                    v-else
                  >
                  </el-image>
                  <div class="upText">
                    船舶内河适航证 <span style="color: red">*</span>
                  </div>
                </div>
                <div class="upBox">
                  <UploadImg
                    :loading="authForm.ShipLicenseFrontURL.loading"
                    :uploaded="authForm.ShipLicenseFrontURL.uploaded"
                    :picUrl="authForm.ShipLicenseFrontURL.picUrl"
                    @update:file="updateFile"
                    @change="uploadCertificate(authForm.ShipLicenseFrontURL)"
                    @deleteImg="deleteImg(authForm.ShipLicenseFrontURL)"
                    v-if="dialogType != 'detail'"
                  >
                  </UploadImg>
                  <el-image
                    :src="authForm.ShipLicenseFrontURL.picUrl"
                    :preview-src-list="srcList"
                    class="img"
                    v-else
                  >
                  </el-image>
                  <div class="upText">
                    船舶营运证正面 <span style="color: red">*</span>
                  </div>
                </div>
                <div class="upBox">
                  <UploadImg
                    :loading="authForm.ShipLicenseBackURL.loading"
                    :uploaded="authForm.ShipLicenseBackURL.uploaded"
                    :picUrl="authForm.ShipLicenseBackURL.picUrl"
                    @update:file="updateFile"
                    @change="uploadCertificate(authForm.ShipLicenseBackURL)"
                    @deleteImg="deleteImg(authForm.ShipLicenseBackURL)"
                    v-if="dialogType != 'detail'"
                  >
                  </UploadImg>
                  <el-image
                    :src="authForm.ShipLicenseBackURL.picUrl"
                    :preview-src-list="srcList"
                    class="img"
                    v-else
                  >
                  </el-image>
                  <div class="upText">
                    船舶营运证反面 <span style="color: red">*</span>
                  </div>
                </div>
                <div class="upBox">
                  <UploadImg
                    :loading="authForm.ShipPhotoURL.loading"
                    :uploaded="authForm.ShipPhotoURL.uploaded"
                    :picUrl="authForm.ShipPhotoURL.picUrl"
                    @update:file="updateFile"
                    @change="uploadCertificate(authForm.ShipPhotoURL)"
                    @deleteImg="deleteImg(authForm.ShipPhotoURL)"
                    v-if="dialogType != 'detail'"
                  >
                  </UploadImg>
                  <el-image
                    :src="authForm.ShipPhotoURL.picUrl"
                    :preview-src-list="srcList"
                    class="img"
                    v-else
                  >
                  </el-image>
                  <div class="upText">船舶图片 <span style="color: red">*</span></div>
                </div>
                <div
                  class="upBox"
                  v-if="
                    dialogType != 'detail' ||
                    (dialogType == 'detail' && authForm.Aisurl.picUrl)
                  "
                >
                  <UploadImg
                    :loading="authForm.Aisurl.loading"
                    :uploaded="authForm.Aisurl.uploaded"
                    :picUrl="authForm.Aisurl.picUrl"
                    @update:file="updateFile"
                    @change="uploadCertificate(authForm.Aisurl)"
                    @deleteImg="deleteImg(authForm.Aisurl)"
                    v-if="dialogType != 'detail'"
                  >
                  </UploadImg>
                  <el-image
                    :src="authForm.Aisurl.picUrl"
                    :preview-src-list="srcList"
                    class="img"
                    v-else
                  >
                  </el-image>
                  <div class="upText">AIS证书</div>
                </div>
                <div
                  class="upBox"
                  v-if="
                    dialogType != 'detail' ||
                    (dialogType == 'detail' && authForm.RadioLicenseURL.picUrl)
                  "
                >
                  <UploadImg
                    :loading="authForm.RadioLicenseURL.loading"
                    :uploaded="authForm.RadioLicenseURL.uploaded"
                    :picUrl="authForm.RadioLicenseURL.picUrl"
                    @update:file="updateFile"
                    @change="uploadCertificate(authForm.RadioLicenseURL)"
                    @deleteImg="deleteImg(authForm.RadioLicenseURL)"
                    v-if="dialogType != 'detail'"
                  >
                  </UploadImg>
                  <el-image
                    :src="authForm.RadioLicenseURL.picUrl"
                    :preview-src-list="srcList"
                    class="img"
                    v-else
                  >
                  </el-image>
                  <div class="upText">电台执照</div>
                </div>
              </div>
          
            </el-col>
            <el-col :span="12">
              <div class="title">基本信息</div>
              <div class="content" style="justify-content: left">
                <el-form-item label="船舶名称" prop="ShipName">
                  <el-input
                    v-model="addForm.ShipName"
                    placeholder="请输入船舶名称"
                    maxlength="32"
                  ></el-input>
                </el-form-item>
                <el-form-item label="船舶登记号" prop="ShipRegNumber">
                  <el-input
                    v-model="addForm.ShipRegNumber"
                    placeholder="请输入船舶登记号"
                    maxlength="32"
                  ></el-input>
                </el-form-item>
                <el-form-item label="船舶类型" prop="ShipType">
                  <el-input
                    v-model="addForm.ShipType"
                    placeholder="请输入船舶类型"
                    maxlength="10"
                  ></el-input>
                </el-form-item>
                <el-form-item label="船舶编号" prop="ShipNO">
                  <el-input
                    v-model="addForm.ShipNO"
                    placeholder="请输入船舶编号"
                    maxlength="32"
                  ></el-input>
                </el-form-item>
                <el-form-item label="船籍港口" prop="ShipPort">
                  <el-input
                    v-model="addForm.ShipPort"
                    placeholder="请输入船籍港口"
                    maxlength="32"
                  ></el-input>
                </el-form-item>
                <el-form-item label="船检登记号" prop="ShipCheckNumber">
                  <el-input
                    v-model="addForm.ShipCheckNumber"
                    placeholder="请输入船检登记号"
                    maxlength="32"
                  ></el-input>
                </el-form-item>
                <el-form-item label="船舶所有人" prop="Shipowner">
                  <el-input
                    v-model="addForm.Shipowner"
                    placeholder="请输入船舶所有人"
                    maxlength="32"
                  ></el-input>
                </el-form-item>
                <el-form-item label="船舶经营人" prop="ShipOperator">
                  <el-input
                    v-model="addForm.ShipOperator"
                    placeholder="请输入船舶经营人"
                    maxlength="32"
                  ></el-input>
                </el-form-item>
                <el-form-item label="使用期限" prop="UsagePeriodDate">
                  <el-date-picker
                    v-model="addForm.UsagePeriodDate"
                    type="date"
                    value-format="yyyy-MM-dd"
                    placeholder="请选择使用期限"
                  ></el-date-picker>
                  <!-- <el-input v-model="addForm.UsagePeriodDate" placeholder="请输入使用期限"></el-input> -->
                </el-form-item>
                <el-form-item label="发证机关" prop="IssuingAuthority">
                  <el-input
                    v-model="addForm.IssuingAuthority"
                    placeholder="请输入发证机关"
                    maxlength="32"
                  ></el-input>
                </el-form-item>
                <el-form-item label="发证日期" prop="IssuingDate">
                  <el-date-picker
                    v-model="addForm.IssuingDate"
                    type="date"
                    value-format="yyyy-MM-dd"
                    placeholder="请选择发证日期"
                  ></el-date-picker>
                </el-form-item>
                <el-form-item label="船舶材料" prop="ShipBodyMaterial">
                  <el-input
                    v-model="addForm.ShipBodyMaterial"
                    placeholder="请输入船舶材料"
                    maxlength="32"
                  ></el-input>
                </el-form-item>
                <el-form-item label="船舶总吨" prop="ShipTonnage">
                  <el-input
                    v-model="addForm.ShipTonnage"
                    placeholder="请输入船舶总吨"
                    onkeyup="value=value.replace(/[^\d.]/g,'').replace(/(\d)(\.)(\d*)(\2*)(\d*)/,'$1$2$3$5').replace(/^(\-)*(\d+)\.(\d\d).*$/,'$1$2.$3')"
                    maxlength="9"
                  ></el-input>
                </el-form-item>
                <el-form-item label="建成日期" prop="ShipCreateDate">
                  <el-date-picker
                    v-model="addForm.ShipCreateDate"
                    type="date"
                    value-format="yyyy-MM-dd"
                    placeholder="请选择建成日期"
                  ></el-date-picker>
                </el-form-item>
                <el-form-item label="改建日期" prop="ShipRenovationDate">
                  <el-date-picker
                    v-model="addForm.ShipRenovationDate"
                    type="date"
                    value-format="yyyy-MM-dd"
                    placeholder="请选择改建日期"
                  ></el-date-picker>
                </el-form-item>
                <el-form-item label="主机功率" prop="TotalPower">
                  <el-input
                    v-model="addForm.TotalPower"
                    placeholder="请输入主机功率"
                    onkeyup="value=value.replace(/[^\d.]/g,'').replace(/(\d)(\.)(\d*)(\2*)(\d*)/,'$1$2$3$5').replace(/^(\-)*(\d+)\.(\d\d).*$/,'$1$2.$3')"
                    maxlength="9"
                  ></el-input>
                </el-form-item>
              </div>

       
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div slot="footer" v-if="dialogType != 'detail'">
        <el-button
          size="medium"
          @click="
            () => {
              showAddDialog = !showAddDialog;
              getShipPage();
            }
          "
          >关闭</el-button
        >
        <el-button type="primary" size="medium" @click="toSubmit()" :loading="loading"
          >保存资料</el-button
        >

        <el-button type="success" @click="toAgree"> 认证通过并下一个</el-button>
        <el-button type="warning" @click="toNext" icon="el-icon-star-off">
          查看下一个</el-button
        >
        <el-button type="danger" @click="toReject" icon="el-icon-close"
          >驳回通过并下一个
        </el-button>
      </div>
      <div slot="footer" v-else>
        <el-button size="medium" @click="showAddDialog = !showAddDialog">关闭</el-button>
      </div>
    </el-dialog>
    <!-- 拒绝原因dialog -->
    <el-dialog width="700px" title="驳回原因" :visible.sync="rejectModal" append-to-body>
      <el-input
        type="textarea"
        maxlength="200"
        placeholder="请输入驳回原因，限制200个汉字"
        v-model="remark"
      ></el-input>
      <div style="display: flex; justify-content: center; margin-top: 16px">
        <el-button size="mini" @click="rejectModal = false">取消</el-button>
        <el-button
          type="primary"
          :loading="loading"
          size="mini"
          @click="sendBack"
          :disabled="!remark"
          >确定
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  ShipPage,
  ShipEdit,
  ShipDetail,
  ShipAgree,
  ShipReject,
  PageStatus,
} from "@/api/transport/ship/shipping";
import UploadImg from "@/components/commonCmpt/uploadImg";
import { upLoadImgByUrl } from "@/api/common/common";
const pics = [
  "RiverLicenseURL",
  "ShipLicenseFrontURL",
  "ShipLicenseBackURL",
  "ShipPhotoURL",
  "Aisurl",
  "RadioLicenseURL",
];
export default {
  components: {
    UploadImg,
  },
  data() {
    return {
      activeCmpt: "0",
      statusList: [
        {
          statusName: "待提交",
          status: "0",
          timeTxt: "添加时间",
        },
        {
          statusName: "待审核",
          status: "10",
          timeTxt: "提交时间",
        },
        {
          statusName: "已通过",
          status: "100",
          timeTxt: "审核时间",
        },
        {
          statusName: "已驳回",
          status: "1",
          timeTxt: "驳回时间",
        },
      ],
      queryForm: {
        ShipNO: "",
        ShipName: "",
        ShipType: "",
        searchDate: ["", ""],
      },
      tableData: [],
      pagination: {
        pagesize: 10,
        page: 1,
        total: 0,
      },
      loading: false,
      showAddDialog: false,
      // 表单校验
      addRule: {
        ShipName: [{ required: true, message: "请输入船舶名称", trigger: "change" }],
        ShipRegNumber: [
          { required: true, message: "请输入船舶登记号", trigger: "change" },
        ],
        ShipType: [{ required: true, message: "请输入船舶类型", trigger: "change" }],
        ShipNO: [{ required: true, message: "请输入船舶编号", trigger: "change" }],
      },
      // 提交表单
      addForm: {
        AscriptionUserID: 0,
        ShipNO: "",
        ShipName: "",
        ShipPort: "",
        ShipRegNumber: "",
        ShipCheckNumber: "",
        Shipowner: "",
        ShipOperator: "",
        UsagePeriodDate: "",
        IssuingAuthority: "",
        IssuingDate: "",
        ShipType: "",
        ShipBodyMaterial: "",
        ShipTonnage: 0,
        ShipCreateDate: "",
        ShipRenovationDate: "",
        PowerType: "",
        TotalPower: 0,
        Remark: "",
      },
      authForm: {
        // 船舶内河适航证
        RiverLicenseURL: {
          loading: false,
          uploaded: false,
          picUrl: "",
          file: "",
          text: "船舶内河适航证",
        },
        //船舶营运证正面
        ShipLicenseFrontURL: {
          loading: false,
          uploaded: false,
          picUrl: "",
          file: "",
          text: "船舶营运证正面",
        },
        //船舶营运证反面
        ShipLicenseBackURL: {
          loading: false,
          uploaded: false,
          picUrl: "",
          file: "",
          text: "船舶营运证反面",
        },
        //船舶图片
        ShipPhotoURL: {
          loading: false,
          uploaded: false,
          picUrl: "",
          file: "",
          text: "船舶图片",
        },
        //AIS证书
        Aisurl: {
          loading: false,
          uploaded: false,
          picUrl: "",
          file: "",
          text: "AIS证书",
        },
        //电台执照
        RadioLicenseURL: {
          loading: false,
          uploaded: false,
          picUrl: "",
          file: "",
          text: "电台执照",
        },
      },
      currentFile: {},
      dialogType: "", // 弹窗类型
      currentItem: "", // 当前操作数据
      srcList: [],
      rejectModal: false,
      remark: "",
    };
  },
  created() {
    this.getShipPage();
  },
  methods: {
    getPageStatus({ thereIsCurrentlyNoNextItemAvailableCallback }) {
      var index = this.tableData.findIndex((x) => x.ID == this.currentItem.ID);
      if (index + 1 == this.tableData.length) {
        //最后一条
        this.$message.warning("暂无下一条");
        setTimeout(() => {
          thereIsCurrentlyNoNextItemAvailableCallback();
          this.showAddDialog = false;
          this.getShipPage();
        }, 1000);
      } else {
        // this.$message.success("跳转下一个成功");
        this.currentItem = this.tableData[index + 1];
        setTimeout(() => {
          this.getDetail(
            this.currentItem.Status != 10 ? "detail" : "edit",
            this.currentItem.ID
          );
        }, 1000);
      }
      // let data = {
      //     ShipType: this.queryForm.ShipType,
      //     ShipNO: this.queryForm.ShipNO,
      //     ShipRegNumber: this.queryForm.ShipRegNumber,
      //     EnterpriseName: this.queryForm.EnterpriseName,
      //     ShipName: this.queryForm.ShipName,
      //     status: Number(this.activeCmpt),
      //     statusTimeStart: this.queryForm.searchDate && this.queryForm.searchDate[0] && (this.queryForm.searchDate[0] + ' 00:00:00'),
      //     statusTimeEnd: this.queryForm.searchDate && this.queryForm.searchDate[1] && (this.queryForm.searchDate[1] + ' 23:59:59'),
      // };
      // PageStatus(data).then(res => {
      //     if (res.data == null) {
      //         this.$message.success("暂无下一个")
      //         this.showAddDialog = false
      //     }
      //     else {
      //         this.$message.success("跳转下一个成功")
      //         this.currentItem = res.data
      //         this.getDetail(this.currentItem.Status != 10 ? 'detail' : 'edit', this.currentItem.ID)
      //     }
      // })
    },
    toNext() {
      const loading = this.$loading({
        lock: true,
        text: "请求中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.getPageStatus({
        thereIsCurrentlyNoNextItemAvailableCallback: () => {
          loading.close();
        },
      });
    },
    // 查询船舶列表
    getShipPage() {
      this.loading = true;
      let data = {
        ShipType: this.queryForm.ShipType,
        ShipNO: this.queryForm.ShipNO,
        ShipRegNumber: this.queryForm.ShipRegNumber,
        EnterpriseName: this.queryForm.EnterpriseName,
        ShipName: this.queryForm.ShipName,
        status: Number(this.activeCmpt),
        statusTimeStart:
          this.queryForm.searchDate &&
          this.queryForm.searchDate[0] &&
          this.queryForm.searchDate[0] + " 00:00:00",
        statusTimeEnd:
          this.queryForm.searchDate &&
          this.queryForm.searchDate[1] &&
          this.queryForm.searchDate[1] + " 23:59:59",
        pageIndex: this.pagination.page,
        pageSize: this.pagination.pagesize,
      };
      ShipPage(data)
        .then((res) => {
          this.pagination.total = res.data.TotalCount;
          this.tableData = res.data.DataList;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // 换页
    handleCurrentChange(val) {
      this.pagination.page = val;
      this.getShipPage();
    },
    //搜索
    search() {
      this.pagination.page = 1;
      this.getShipPage();
    },
    // 清空
    resetForm() {
      this.queryForm = {
        ShipNO: "",
        ShipName: "",
        ShipType: "",
        ShipRegNumber: "",
        EnterpriseName: "",
        searchDate: ["", ""],
      };
      this.search();
    },
    // 获取详情
    getDetail(type, id) {
      this.dialogType = type;
      const loading = this.$loading({
        lock: true,
        text: "请求中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.srcList = [];
      ShipDetail({ id })
        .then((res) => {
          this.addForm = JSON.parse(JSON.stringify(res.data.ShipBaseInfo));
          for (let i = 0; i < pics.length; i++) {
            let obj = pics[i];
            if (obj == "Aisurl") {
              this.authForm["Aisurl"].picUrl = res.data.ShipConfirmInfo["AISURL"];
            } else {
              this.authForm[obj].picUrl = res.data.ShipConfirmInfo[obj];
            }
            this.srcList.push(this.authForm[obj].picUrl);
          }
          this.showAddDialog = true;
        })
        .finally(() => {
          loading.close();
        });
    },
    toSubmit() {
      let shipConfirmInfo = {};
      for (let i = 0; i < pics.length; i++) {
        let obj = pics[i];
        if (i < 4) {
          if (!this.authForm[obj].picUrl) {
            this.$message.warning(`请上传${this.authForm[obj].text}！`);
            return;
          }
        } else {
          if (!this.authForm[pics[4]].picUrl && !this.authForm[pics[5]].picUrl) {
            this.$message.warning(
              `${this.authForm[pics[4]].text}和${
                this.authForm[pics[5]].text
              }必须上传其中一张！`
            );
            return;
          }
        }
        shipConfirmInfo[obj] = this.authForm[obj].picUrl;
      }
      this.$refs["addForm"].validate((valid) => {
        if (valid) {
          this.loading = true;
          ShipEdit({
            shipBaseInfo: this.addForm,
            shipConfirmInfo: shipConfirmInfo,
            id: this.currentItem.ID,
          })
            .then((res) => {
              this.$message.success("操作成功");
              this.getDetail("edit", this.currentItem.ID);
              this.getShipPage();
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
    // 查看详情
    toDetail(item) {
      this.currentItem = item;
      this.getDetail(item.Status != 10 ? "detail" : "edit", item.ID);
    },
    // 通过审核
    toAgree() {
      this.$confirm("确定通过审核吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        const loading = this.$loading({
          lock: true,
          text: "请求中...",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
        ShipAgree({ id: this.currentItem.ID })
          .then((res) => {
            this.$message.success("操作成功");
            this.getPageStatus({
              thereIsCurrentlyNoNextItemAvailableCallback: () => {
                loading.close();
              },
            });
            // this.showAddDialog = false
            //   this.getShipPage();
          })
          .finally(() => {
            loading.close();
          });
      });
    },
    // 驳回
    toReject() {
      this.rejectModal = true;
      this.remark = "";
    },
    sendBack() {
      this.$confirm("确定驳回吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        const loading = this.$loading({
          lock: true,
          text: "请求中...",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
        ShipReject({ id: this.currentItem.ID, remark: this.remark })
          .then((res) => {
            this.$message.success("操作成功");
            this.rejectModal = false;
            this.getPageStatus({
              thereIsCurrentlyNoNextItemAvailableCallback: () => {
                loading.close();
              },
            });
          })
          .finally(() => {
            loading.close();
          });
      });
    },
    //上传证件
    uploadCertificate(field) {
      field.loading = true;
      var reader = new FileReader();
      reader.readAsDataURL(this.currentFile);
      reader.onload = () => {
        upLoadImgByUrl({ FileBase64: reader.result, FileType: "Other" })
          .then((res) => {
            field.loading = false;
            field.uploaded = true;
            if (res.data) {
              field.picUrl = res.data;
            }
          })
          .catch(() => {})
          .finally(() => {
            field.loading = true;
            field.uploaded = true;
          });
      };
    },
    //删除图片
    deleteImg(field) {
      field.uploaded = false;
      field.loading = false;
      field.picUrl = "";
    },
    //设置当前组件正在操作的文件
    updateFile(file) {
      this.currentFile = file;
    },
  },
};
</script>
<style lang="scss" scoped>
.shipping {
  .topbtns {
    padding-bottom: 10px;
    margin: 10px 0px;
    border-bottom: 1px solid #eee;
  }
}

.showAddDialog {
  ::v-deep .el-dialog {
    margin-top: 6vh !important;
  }

  ::v-deep .el-dialog__header {
    border-bottom: 1px solid #eee;
  }

  ::v-deep .el-dialog__footer {
    border-top: 1px solid #eee;
    text-align: center;
  }

  ::v-deep .el-dialog__body {
    padding: 20px;
  }

  .addForm {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    .title {
      color: #2081ff;
      position: relative;
      text-indent: 15px;
      font-size: 14px;
      margin-bottom: 10px;

      &::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        width: 3px;
        height: 14px;
        border-radius: 5px;
        background: #2081ff;
      }
    }

    .content {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly;

      .upBox {
        text-align: center;

        .upText {
          font-size: 14px;
          font-weight: bold;
          margin: 10px 0 0 0;
          color: #333333;
        }
      }

      .el-upload__tip {
        color: #7e7e7e;
      }
    }

    .img {
      width: 278px;
      height: 174px;
      border-radius: 10px;
    }
  }

  ::v-deep .el-form-item__content {
    margin-right: 20px !important;

    .el-input__inner,
    .el-date-editor {
      width: 200px;
    }
  }
}
</style>
